import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import PageHeader from '~/components/PageHeader';
import { Paragraph, Heading2, Link } from '@entur/typography';
import { PrimaryButton } from '@entur/button';
import { ImageDisplay } from '~/components/ImageDisplay';
export const query = graphql`
query NotFoundquery {
   file(sourceInstanceName: {eq: "downloads"}, name: {eq: "Sheep"}) {
    image: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <div style={{
      textAlign: "center"
    }}>
      <ImageDisplay style={{
        paddingInline: "20%"
      }} fluidSource={props.data.file.image.fluid} alt="Bomstasjon som sperrer veien videre. Tegning" mdxType="ImageDisplay" />
      <Heading2 mdxType="Heading2">Bomtur</Heading2>
      <Paragraph mdxType="Paragraph">
  Adressen du forsøkte å gå til finnes ikke
  <br />
  Eller så har siden blitt flyttet til et annet sted.
      </Paragraph>
      <Link href="/" mdxType="Link">
  <PrimaryButton mdxType="PrimaryButton">Gå til forsiden</PrimaryButton>
      </Link>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      